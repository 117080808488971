import { defineStore } from "pinia";
import type {
  RestaurantPage,
  RestaurantModel,
  RestaurantIncluded,
} from "~/types/Restaurant";
import { checkIsNewRestaurant } from "~/services/restaurant/common";
import alert from "~/lib/alert";
import { useHandleError } from "~/composables/useHandleError";
import { rebuildAssetURL } from "~/helpers/url";
import { useReport } from "~/composables/useReport";
import type { Tags } from "~/types/Tag";
import { DEFAULT_ERROR_MESSAGE } from "~/constants";
import useConfigStore from "~/stores/config";

const useRestaurantDetailStore = defineStore("restaurantDetail", {
  state: (): RestaurantPage => {
    return {
      isLoading: false,
      restaurantId: "",
      restaurantCookingTime: 0 as number | string,
      acceptVoucher: false,
      acceptKids: false,
      tncImageUrl: "",
      supportOrderNow: false,
      weekdayOpeningHours: null,
      gbPrimepayPublicKey: "",
      name: "",
      icon: "",
      expiryDate: "",
      diningStyle: "",
      branchId: 0,
      branchesLink: "",
      openingHoursShort: "",
      availablePackageTypes: [],
      reviewCount: 0,
      reviewScore: "0",
      reviewStarsCount: [],
      cuisines: [],
      diningStyles: [],
      cuisine: {
        id: 1,
        name: "Cuisine",
      },
      primaryDiningStyle: {
        id: 0,
        name: "",
      },
      location: {
        id: 1,
        name: "Location",
      },
      tags: [] as Tags,
      featuredImages: [],
      lastOrder: "",
      reservationSystemOnly: false,
      vrLink: "",
      totalCovers: 0,
      reviews: [],
      reviewsData: [
        {
          rating: 5,
          data: [],
          page: 1,
          anyNextReviews: false,
        },
        {
          rating: 4,
          data: [],
          page: 1,
          anyNextReviews: false,
        },
        {
          rating: 3,
          data: [],
          page: 1,
          anyNextReviews: false,
        },
        {
          rating: 2,
          data: [],
          page: 1,
          anyNextReviews: false,
        },
        {
          rating: 1,
          data: [],
          page: 1,
          anyNextReviews: false,
        },
        {
          rating: 0,
          data: [],
          page: 1,
          anyNextReviews: false,
        },
      ],
      lat: 0,
      lng: 0,
      daysInAdvance: 0,
      address: "",
      mapLocation: "",
      overallScore: {
        average: 0,
        overall: {
          food: 0,
          ambience: 0,
          service: 0,
          value: 0,
        },
      },
      description: "",
      parking: "",
      corkageCharge: "",
      page: 1,
      pageSize: 10,
      loadingReview: false,
      anyNextReviews: false,
      bloggerReview: [],
      bloggerReviewPage: 1,
      anyNextBloggerReview: false,
      selectedStar: 0,
      allowBooking: false,
      canonicalLink: "",
      branchs: [],
      loadingReviewsCount: false,
      restaurantNameEn: "",
      restaurantNameTh: "",
      slug: "",
      priceAndPricingType: {
        amount: 0,
        currency: "",
        symbol: "",
        format: "",
        pricingType: "",
      },
      lowestPrice: "",
      videos: [],
      breadcrumbs: [],
      isLoadingLoadMore: false,
      customSeats: [],
      largestTable: 0,
      minPartySize: 0,
      anyDeliveryPackage: false,
      anyDineInPackage: false,
      googleReviewScore: 0,
      totalGoogleReviews: 0,
      acceptedCreditCards: [] as string[],
    };
  },
  getters: {
    isNewRestaurant(): boolean {
      return checkIsNewRestaurant(this.reviewCount);
    },
    isRestaurantExpired(state) {
      return state.allowBooking === false;
    },
    bloggerReviewCount(state) {
      return state.bloggerReview.length;
    },
    isRestaurantAcceptKids(state) {
      return state.acceptKids;
    },
    isRestaurantHasCustomSeats(state) {
      return (
        (Array.isArray(state.customSeats) && state.customSeats.length > 0) ||
        false
      );
    },
  },
  actions: {
    async getRestaurantData({ slug }: { slug: string }) {
      const defaultErrorMessage =
        "Oops, something went wrong, failed get restaurant data";
      try {
        const configStore = useConfigStore();
        const useMockRestaurantData =
          configStore.mockData.includes("restaurant");
        const { getRestaurantBySlug } = await import(
          "~/api/restaurant/getBySlug"
        );
        const { isSuccess, data, message } = await getRestaurantBySlug({
          slugOrId: slug,
          mock: useMockRestaurantData,
        });
        return {
          data,
          success: isSuccess,
          message,
        };
      } catch (err) {
        const message = useHandleError({
          defaultErrorMessage,
          err,
          errorPath: "restaurantDetail/getRestaurantData",
        });
        return {
          success: false,
          message,
          data: null,
        };
      }
    },
    async setRestaurantData({
      data,
      included,
    }: {
      data: RestaurantModel;
      included: RestaurantIncluded;
    }) {
      try {
        const { getYoutubeIds } = await import("~/helpers/restaurant");
        const { packageTypeOrder } = await import("~/helpers/pack");
        const {
          id,
          name,
          logoUrl,
          branchId,
          primaryCuisine,
          primaryLocation,
          openingHoursShort,
          weekdayOpeningHours,
          reviewsCount,
          reviewsScore,
          lastBookingWasMade,
          totalCovers,
          reservationSystemOnly,
          lat,
          lng,
          daysInAdvance,
          diningStyle,
          diningStyles,
          primaryDiningStyle,
          address,
          mapLocation,
          myMoobanVrLink,
          description,
          parking,
          corkageCharge,
          slug,
          allowBooking,
          canonicalLink,
          names,
          cuisines,
          priceAndPricingType,
          priceSummaries,
          videos,
          availablePackageTypes,
          breadcrumbs,
          acceptKids,
          acceptVoucher,
          tncImageUrl,
          customSeats,
          largestTable,
          minPartySize,
          expiryDate,
          anyDineInPackage,
          anyDeliveryPackage,
          cookingTime,
          gbPrimepayPublicKey,
          googleReviewScore,
          totalGoogleReviews,
          acceptedCreditCards,
        } = data;
        this.acceptKids = acceptKids || false;
        this.acceptVoucher = acceptVoucher || false;
        this.tncImageUrl = rebuildAssetURL(tncImageUrl || "");
        this.restaurantId = id;
        this.name = name || "";
        this.availablePackageTypes = packageTypeOrder(
          availablePackageTypes || []
        );
        this.daysInAdvance = daysInAdvance || 0;
        this.lastOrder = lastBookingWasMade || "";
        this.branchId = branchId || 0;
        this.diningStyle = diningStyle || "";
        this.diningStyles = diningStyles || [];
        this.cuisine = {
          id: primaryCuisine?.id || 0,
          name: primaryCuisine?.name || "",
        };
        this.primaryDiningStyle = {
          id: primaryDiningStyle?.id || 0,
          name: primaryDiningStyle?.name || "",
        };
        this.location = {
          id: primaryLocation?.id || 0,
          name: primaryLocation?.name || "",
        };
        this.icon = rebuildAssetURL(logoUrl?.medium || "");
        this.openingHoursShort = openingHoursShort || "";
        this.reviewCount = reviewsCount || 0;
        this.cuisines = cuisines || [];
        this.reviewScore =
          typeof reviewsScore === "number"
            ? reviewsScore % 1 === 0
              ? `${reviewsScore}.0`
              : `${reviewsScore}`
            : "0";
        this.totalCovers = totalCovers || 0;
        this.weekdayOpeningHours = weekdayOpeningHours;
        this.vrLink = myMoobanVrLink || "";
        this.description = description || "";
        this.parking = parking || "";
        this.slug = slug || "";
        this.corkageCharge = corkageCharge || "";
        this.canonicalLink = canonicalLink || "";
        this.allowBooking = allowBooking || true;
        this.reservationSystemOnly = reservationSystemOnly || false;
        if (!this.featuredImages.length && included) {
          included.forEach((inc) => {
            if (inc.type === "restaurants-pictures") {
              this.featuredImages.push({
                id: inc.id,
                caption: inc.attributes.caption,
                src: rebuildAssetURL(inc.attributes.item.url),
              });
            }
          });
        }
        this.lat = typeof lat === "string" ? parseFloat(lat) : lat || 0;
        this.lng = typeof lng === "string" ? parseFloat(lng) : lng || 0;
        this.address = address ?? "";
        this.mapLocation = mapLocation || "";
        this.parking = parking || "";
        this.restaurantNameEn = names ? names.en : "";
        this.restaurantNameTh = names ? names.th : "";
        this.restaurantCookingTime = cookingTime || 0;
        this.expiryDate = expiryDate || "";
        this.priceAndPricingType = {
          amount: priceAndPricingType?.amount,
          currency: priceAndPricingType?.currency,
          symbol: priceAndPricingType?.symbol,
          format: priceAndPricingType?.format,
          pricingType: priceAndPricingType?.pricingType,
        };
        const arrayPrice = priceSummaries?.map((item) => {
          return Number(item?.lowestPrice.replace(/[^0-9.]+/g, ""));
        });
        this.lowestPrice = Array.isArray(arrayPrice)
          ? Math.min(...arrayPrice).toString()
          : "";
        this.videos = Array.isArray(videos) ? getYoutubeIds(videos) : [];
        this.breadcrumbs = Array.isArray(breadcrumbs) ? breadcrumbs : [];
        this.customSeats = customSeats || [];
        this.largestTable = largestTable || 0;
        this.minPartySize = minPartySize || 0;
        this.anyDineInPackage = anyDineInPackage || false;
        this.anyDeliveryPackage = anyDeliveryPackage || false;
        this.gbPrimepayPublicKey = gbPrimepayPublicKey || "";
        this.googleReviewScore = googleReviewScore || 0;
        this.totalGoogleReviews = totalGoogleReviews || 0;
        this.acceptedCreditCards = acceptedCreditCards || [];
      } catch (err) {
        const message = `${DEFAULT_ERROR_MESSAGE}, failed set restaurant data`;
        useReport({
          level: "error",
          message,
        });
      }
    },
    async getBloggerReview() {
      try {
        const { getBloggerReview } = await import(
          "~/api/restaurant/getBloggerReview"
        );
        const { data, isSuccess, links } = await getBloggerReview({
          restaurantId: this.restaurantId,
          pageSize: 10,
          pageNumber: this.bloggerReviewPage,
        });
        if (data && isSuccess) {
          this.anyNextBloggerReview = !!links?.next;
          if (this.bloggerReviewPage === 1) {
            this.bloggerReview = data;
          } else {
            this.bloggerReview = [...this.bloggerReview, ...data];
          }
        }
      } catch (error: any) {
        alert.error(error);
      }
    },
    async getRestaurantBranches() {
      try {
        if (!this.branchId) return;
        const { getBranchRestaurant } = await import(
          "~/api/restaurant/getBranches"
        );
        const { data, success, message } = await getBranchRestaurant({
          id: this.branchId,
        });

        if (!success) {
          if (message) {
            alert.error(message);
            return;
          }
        }
        if (data.length) {
          this.branchs = data;
        }
      } catch (err) {
        const message = `${DEFAULT_ERROR_MESSAGE}, failed get restaurant branches`;
        alert.error(message);
        useReport({
          level: "error",
          message,
          errorException: err,
        });
      }
    },
  },
});

export { useRestaurantDetailStore };
